:root {
  --h100: 100%;
  --auto: auto;
  --hvh100: 100vh;
  --dblock: block;
  --dflex: flex;
  --center: center;
  --right: right;
  --zero: 0;
  --halfrem: 0.5rem;
  --onerem: 1rem;
  --onehalfrem: 1.5rem;
  --tworem: 2rem;
  --none: none;
  --primary: #98c2b9;
  --secondary: #464646;
  --normal: normal;
  --bold: bold;
  --colorgrey: rgba(0, 0, 0, 0.54);
}

body {
  margin: 0;
  padding: 0;
  color: var(--secondary);
}

a {
  text-decoration: var(--none);
  color: var(--primary);
}

.img-fluid {
  display: var(--dblock);
  max-width: var(--h100);
  height: var(--auto);
}

.text-center {
  text-align: var(--center) !important;
}
.text-end {
  text-align: var(--right) !important;
}
/* Margin Start */
.m-0 {
  margin: var(--zero) !important;
}

.m-1 {
  margin: var(--halfrem) !important;
}

.m-2 {
  margin: var(--onerem) !important;
}

.m-3 {
  margin: var(--onehalfrem) !important;
}

.m-4 {
  margin: var(--tworem) !important;
}
.ml-2 {
  margin-left: var(--tworem) !important;
}
.mb-0 {
  margin-bottom: var(--zero) !important;
}

.mb-1 {
  margin-bottom: var(--halfrem) !important;
}

.mb-2 {
  margin-bottom: var(--onerem) !important;
}

.mb-3 {
  margin-bottom: var(--onehalfrem) !important;
}

.mb-4 {
  margin-bottom: var(--tworem) !important;
}

.mt-1 {
  margin-top: var(--halfrem) !important;
}

.mt-2 {
  margin-top: var(--onerem) !important;
}

.mt-3 {
  margin-top: var(--onehalfrem) !important;
}

.mt-4 {
  margin-top: var(--tworem) !important;
}

.m-auto {
  margin: var(--auto) !important;
}

.mx-auto {
  margin: 0 var(--auto) !important;
}

.my-auto {
  margin: var(--auto) 0 !important;
}

/* Margin End */
/* Padding Start */
.p-0 {
  padding: var(--zero) !important;
}

.p-1 {
  padding: var(--halfrem) !important;
}

.p-2 {
  padding: var(--onerem) !important;
}

.p-3 {
  padding: var(--onehalfrem) !important;
}

.p-4 {
  padding: var(--tworem) !important;
}

.pt-1 {
  padding-top: var(--halfrem) !important;
}

.pt-2 {
  padding-top: var(--onerem) !important;
}

.pt-3 {
  padding-top: var(--onehalfrem) !important;
}

.pt-4 {
  padding-top: var(--tworem) !important;
}

.pb-1 {
  padding-bottom: var(--halfrem) !important;
}

.pb-2 {
  padding-bottom: var(--onerem) !important;
}

.pb-3 {
  padding-bottom: var(--onehalfrem) !important;
}

.pb-4 {
  padding-bottom: var(--tworem) !important;
}

.px-1 {
  padding: var(--halfrem) 0 !important;
}

.px-2 {
  padding: var(--onerem) 0 !important;
}

.px-3 {
  padding: var(--onehalfrem) 0 !important;
}

.px-4 {
  padding: var(--tworem) 0 !important;
}

.py-1 {
  padding: 0 var(--halfrem) !important;
}

.py-2 {
  padding: 0 var(--onerem) !important;
}

.py-3 {
  padding: 0 var(--onehalfrem) !important;
}

.py-4 {
  padding: 0 var(--tworem) !important;
}
.radius-05 {
  border-radius: var(--halfrem) !important;
}

.radius-1 {
  border-radius: var(--onerem) !important;
}

.d-none {
  display: var(--none);
}

.text-normal {
  font-weight: var(--normal);
}

.text-bold {
  font-weight: var(--bold);
}
.login-banner {
  width: 100%;
  height: 100vh;
}

.text-grey {
  color: var(--colorgrey) !important;
}
.signup-section {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.owl-carousel.owl-theme .owl-nav button.owl-prev,
.owl-carousel.owl-theme .owl-nav button.owl-next {
  padding: 8px 18px !important;
  border-radius: 50px;
}
.btn-login a {
  display: var(--dflex);
  align-items: center;
}
.btn-login a span {
  color: var(--secondary);
}
button.owl-prev {
  position: absolute;
  top: 40%;
  left: 0;
  background-color: #fff !important;
  border: 2px solid #4f8d7d !important;
  color: #4f8d7d !important;
  font-size: 1.5rem !important;
}

button.owl-next {
  position: absolute;
  top: 40%;
  right: 0;
  background-color: #fff !important;
  border: 2px solid #4f8d7d !important;
  color: #4f8d7d !important;
  font-size: 1.5rem !important;
}

.item-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.bg-restaurant {
  background: url('../images/bg-restuarant.jpg') no-repeat;
}

.bg-automotive {
  background: url('../images/bg-automotive.jpg') no-repeat;
}

.file-upload label {
  padding: 0;
  display: inline;
  line-height: 0;
}
.file-upload svg {
  fill: #4f8d7d;
}
.price-strikethrough {
  text-decoration: line-through;
}
.bg-red {
  background-color: #ef5350;
  color: #fff;
}
.text-success {
  color: #98c2b9;
}
.login-link:last-child {
  padding-right: 0;
}
.user-name {
  margin-left: var(--halfrem);
}
.slide-content .MuiTypography-root.MuiTypography-h1 {
  font-size: 3rem;
}
.slide-content .MuiButtonBase-root.MuiButton-root.btn-slide {
  padding: 0.5rem 1.4rem;
  font-size: 1.2rem;
}
.how-it-works iframe {
  width: 100%;
  height: 100%;
  min-height: 330px;
}
@media only screen and (max-width: 1199px) {
  .slide-content .MuiTypography-root.MuiTypography-h1 {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 768px) {
  .d-xs-none {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .notistack-Snackbar {
    font-weight: bold;
    .notistack-MuiContent-success {
      background-color: #98c2b9 !important;
    }
  }

  .one-day-advertisement-box,
  .multiple-day-advertisement-box {
    font-size: 18px !important;
  }
}
/* RESPONSIVE CSS START */
@media (min-width: 1200px) and (max-width: 1536px) {
}

@media (min-width: 992px) and (max-width: 1199px) {
}

@media (min-width: 768px) and (max-width: 541px) {
  .d-xs-none {
    display: none;
  }

  .px-4 {
    padding: var(--onerem) !important;
  }
}
@media (min-width: 320px) and (max-width: 540px) {
  .dialog-consumer form {
    margin: 0;
    width: 100%;
  }
  .dialog-merchant form {
    margin: 14px;
    width: 100%;
  }
  .btn-user {
    display: contents;
  }
  .btn-user svg {
    font-size: var(--tworem) !important;
  }
  .user-name {
    display: none;
    margin-left: var(--none);
  }
  .btn-slide {
    font-size: 12px !important;
    padding: 4px !important;
  }
  .slide-content .MuiTypography-root.MuiTypography-h1 {
    font-size: 0.8rem;
  }
  .howitwork-dialog
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiDialog-paper {
    margin: 8px;
    width: 100%;
  }
  .how-it-works iframe {
    width: 100%;
    height: 100%;
  }
  .howitwork-dialog h2, .howitwork-dialog .MuiDialogContent-root{
    padding: 8px !important;
  }
  .btn-plan{
    font-size: 14px !important;
    padding: 4px 8px !important;
  }
}
/* RESPONSIVE CSS END */
