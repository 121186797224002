/* #payment-element { */
.Input--invalid {
  box-shadow: none !important;
}
/* } */

.card-form {
  .MuiFormHelperText-root.Mui-error {
    margin: 0;
  }
}
